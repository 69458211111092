
import './App.css';
import Head from './components/Header.js'

function App() {
  return (
    <div className="App">
      <Head />
      
    </div>
  );
}

export default App;
