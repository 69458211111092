function Head() {
    return (
      <header className="App">
      <h1>Cin City Running Group</h1>
      <div class="nav">
          <ul>
              <li>Home</li>
              <li>Races</li>
              <li>Events</li>
              <li>About</li>
          </ul>
      </div>  
      </header>
    );
  }
  
  export default Head;